.BaseLayout {
  .ant-layout {
    min-height: 100vh;
    max-width: 600px !important;
    margin: 0 auto;
  }

  .ant-layout-content {
    width: 100%;
    margin: 0 auto !important;
    background: none !important;
  }

  .ant-descriptions-item-label {
    font-weight: bold;
    color: #000;
  }

  &__header {
    height: 60px;
    line-height: 60px;
    .logo {
      position: absolute;
      width: 200px;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
    }
    .user {
      font-size: 20px;
      margin: 0 20px;
    }
  }
  &__content {
    min-height: 280;
    background: "#ddd";
  }
}

// 関連Issue: https://github.com/ant-design/ant-design/issues/23111
.ant-layout {
  position: relative;
  overflow: hidden;
  min-width: 100vw;
}
