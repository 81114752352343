.InvoiceDetailLineList {
  display: flex;
  &__container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    width: 97%;
    &__table {
      border: 1px solid rgb(240, 240, 240);
    }
    &__title {
      margin: 0;
      padding: 0;
    }
  }
}
