.RatingButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  &__buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    margin-bottom: 24px;
    &__button {
      width: 60px !important;
      height: 60px !important;
      margin: 12px 36px 4px;
      &__icon {
        font-size: 30px !important;
      }
    }
  }
}
