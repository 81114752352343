.Message {
  &__item {
    display: flex;
    align-items: flex-end;
    width: 100%;
    & + & {
      margin-top: 10px;
    }
  }
  &__deleted {
    font-size: 12px;
    text-align: center;
    margin: 2px 0;
  }
  &__box {
    font-size: 12px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    background: #ebebeb;
    min-width: 40px;
    max-width: 360px;
  }
  &__body {
    display: inline-block;
    white-space: pre-wrap;
    padding: 12px 8px 10px;
  }
  &__meta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
    margin: 4px 10px;
  }
  & + & {
    margin-top: 10px;
  }
  &--依頼者 {
    .Message__item {
      flex-direction: row-reverse;
    }
    .Message__box {
      background: #b7ebc7;
    }
  }
}
