.ProjectBasic {
  h2 {
    margin-top: 0 !important;
  }
  &__descriptions {
    .ant-descriptions-row > th {
      padding-bottom: 0 !important;
    }
    &__keyLocation,
    &__returnKeyLocation,
    &__workableDateRange {
      &__text {
        margin-right: 16px;
      }
    }
  }
}
