.MessageFiles {
  img {
    object-fit: contain;
  }
  &--1 {
    width: 200px;
    .ant-image {
      width: 100%;
    }
  }
  &--2 {
    width: 320px;
    display: flex;
    justify-content: space-between;
    .ant-image {
      width: 49%;
    }
  }
}

.PdfPreview {
  position: relative;
  &:hover {
    cursor: pointer;
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "Preview";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s;
  }
}
