.TotalAmountTable {
  &__header {
    background-color: #e6e4e4 !important;
  }
  .ant-table-tbody {
    .ant-table-cell {
      height: 54px;
      padding: 4px 4px !important;
    }
  }
}
