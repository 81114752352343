* {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Hiragino Kaku Gothic ProN",
    "Hiragino Sans", "ヒラギノ角ゴ ProN W3", Arial, メイリオ, Meiryo, system-ui, sans-serif;
}

body {
  margin: 0;
}

.note {
  font-size: 12px;
  margin-top: 0;
}

.Header {
  position: relative;
  .logo {
    position: absolute;
    width: 200px;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

h1 {
  font-size: 20px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

.Gallery {
  max-width: 1000px;
  width: 100%;
  aspect-ratio: 4 / 2;
  div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .div1 {
    background-color: red;
    height: 100%;
  }
  &--grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    aspect-ratio: 4 / 2;
    .div1 {
      grid-area: 1 / 1 / 5 / 3;
    }
    .div2 {
      grid-area: 1 / 3 / 3 / 4;
      background-color: green;
    }
    .div3 {
      grid-area: 3 / 3 / 5 / 4;
      background-color: yellow;
    }
  }
}
