.ProposedEstimateTable {
  width: 100%;
  &__row {
    &__changed {
      background-color: rgb(243, 252, 246);
    }
  }
  .ant-table-thead {
    tr > th {
      border-radius: 0 !important;
    }
    .ant-table-cell {
      height: 20px !important;
      text-align: center;
      background-color: #e6e4e4;
      padding: 4px 4px !important;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        height: 20px !important;
        padding: 4px 4px !important;
      }
    }
  }
  &__col {
    text-align: center;
    padding: 4px 4px !important;
    &__itemName {
      width: 150px;
      white-space: nowrap !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    &__quantity {
      text-align: right;
    }
    &__unitPrice {
      text-align: right;
    }
  }
}
