.ProjectId {
  position: relative;
  &__detail {
    padding: 24px 24px 0;
    max-width: 1200px;
    margin: 0 auto;
    &__overView {
      display: flex;
      flex-direction: row;
      padding-bottom: 24px;
      border-bottom: 1px solid #dadada;
      &__gallery {
        width: 56%;
        padding-right: 24px;
        border-right: 1px solid #dadada;
      }
      &__basicInfo {
        width: 44%;
        padding-left: 24px;
      }
    }
  }
}

section + section {
  padding-bottom: 24px;
  border-bottom: 1px solid #dadada;
}
