.ConstructionCollapse {
  &__item {
    display: flex;
    &__label {
      font-size: 16px;
    }
    &__before,
    &__after,
    &__rework {
      width: 47%;
      &__title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
        text-align: center;
      }
    }
    &__arrow {
      width: 6%;
      display: flex;
      justify-content: center;
      font-size: 36px;
    }
  }
}
