.AmountReferenceTable {
  .ant-table-header {
    border-radius: 0 !important;
  }
  .ant-table-thead {
    tr > th {
      height: 36px;
      padding: 4px 12px !important;
      border-radius: 0 !important;
    }
    .ant-table-cell {
      text-align: center;
      background-color: #e6e4e4;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        height: 36px;
        padding: 4px 12px !important;
      }
    }
  }
}
