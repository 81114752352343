.MessageInput {
  padding: 10px;
  background: #fff;
  form {
    display: flex;
  }
  &__input,
  &__button {
    margin: 0;
  }
  &__input {
    width: 100%;
  }
  &__button {
    margin-left: 8px;
  }
  &__picture {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border: 1px solid rgb(217, 217, 217);
    margin-right: 8px;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
      border-color: rgb(36, 117, 58);
      background-color: rgb(217, 217, 217);
    }
    &__icon {
      font-size: 24px;
    }
    &__selecting {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-weight: 700;
    }
  }
}
