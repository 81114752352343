.ConstructionImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  &__noImages {
    font-size: 16px;
    text-align: center;
  }
  &__imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 4px;
    width: auto !important;
    height: 120px !important;
    .ant-image {
      height: 120px !important;
    }
    .ant-image-img {
      height: 100% !important;
      object-fit: contain !important;
    }
  }
}
