.EstimateDrawer {
  &__form {
    width: 1200px;
    margin: 0 auto;
  }
  &__addRow {
    text-align: right;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  &__totalAmountTable {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
  &__checkbox {
    display: flex;
    justify-content: flex-end;
    &__text {
      margin-left: 12px;
    }
  }
  &__buttonContainer {
    display: flex;
    justify-content: space-around;
    margin: 48px 0;
    button {
      margin: 0 48px;
    }
  }
}
