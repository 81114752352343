.ChatDetail {
  height: 100%;
  display: flex;
  flex-flow: column;
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    &__icon {
      position: absolute;
      width: 45px;
    }
    &__title {
      flex: 1;
      text-align: center;
    }
  }
  &__caution {
    display: flex;
    padding: 4px;
    border: 1px solid rgba(5, 5, 5, 0.06);
    background-color: rgb(249, 250, 251);
    margin-bottom: 4px;
    &__icon {
      margin-right: 2px;
      color: #faad14;
    }
    &__text {
      font-size: 12px;
    }
  }
  &__text {
    text-align: center;
    font-size: 14px;
  }
}
