.PdfViewer {
  background: #fff;
  &__header {
    position: sticky;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    button {
      color: #fff;
    }
  }
}
