.Carousel {
  $galleryHeight: 380px;
  background-color: rgb(236, 236, 236);
  .slick-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  &__dots {
    background-color: black !important;
    opacity: 0.4 !important;
    height: 30px !important;
    bottom: 0 !important;
    > li.slick-active {
      width: 16px !important;
    }
    > li > button {
      top: 9px !important;
      width: 12px !important;
      height: 12px !important;
      border: 1px solid rgb(255, 255, 255) !important;
      border-radius: 6px !important;
    }
  }
  &__image {
    width: 100% !important;
    height: 380px !important;
    .ant-image-img {
      height: 100% !important;
      object-fit: contain !important;
    }
  }
}
