.CommentDrawer {
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }
  &__list {
    flex: 1;
    overflow-y: auto;
  }
  &__field {
    margin-top: 8px;
    width: 100%;
    display: flex;
    .FormItem {
      width: 100%;
      margin-right: 16px;
    }
  }
  &__text {
    text-align: center;
    font-size: 14px;
  }
}
