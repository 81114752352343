.ProjectHeader {
  padding: 0 24px;
  height: 72px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
  &__leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 20px;
      padding: 0 12px;
      color: rgb(0, 0, 0);
    }
    &__title {
      display: flex;
      align-items: center;
      &__projectName {
        margin-right: 16px;
      }
      &__status {
        font-size: 16px;
        padding: 4px 8px;
      }
    }
    &__workerViewCount {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
      &__icon {
        font-size: 20px;
        padding: 0 4px;
      }
      &__number {
        font-size: 14px;
        padding: 0 8px;
      }
    }
    &__messageContainer {
      color: red;
      margin-left: 20px;
    }
  }
  &__action {
    display: flex;
    align-items: center;
    button {
      margin: 0 8px;
    }
    &__downloadBtn {
      display: flex;
      align-items: center;
      .anticon-download {
        font-size: 18px !important;
      }
    }
  }
  &__workableDateRangeModal {
    .ant-modal-body {
      text-align: center;
    }
    &__rangePicker {
      margin-bottom: 28px;
    }
  }
}
