.IdentityVerifyModal {
  &__imageContainer {
    margin: 24px auto;
    display: flex;
    justify-content: space-around;
    &__image {
      padding: 0 24px;
    }
  }
  &__buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 20px;
  }
}
