.WorkRatingModal {
  &__worker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__avatar {
      width: 100px;
      height: 100px;
    }
    &__name {
      font-size: 20px;
      font-weight: bold;
      margin-top: 10px;
    }
  }
  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    &__text {
      font-size: 16px;
      font-weight: 600;
    }
    &__star {
      font-size: 32px;
      flex-direction: row;
      margin-bottom: 18px;
    }
  }
}
