.WorkerList {
  &__filterContainer {
    display: flex;
    padding: 10px;
    gap: 10px;
    &__form {
      width: 100%;
      height: auto;
    }
  }
  &__table {
    .ant-table-thead {
      .ant-table-cell {
        padding-top: 12px !important;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          height: 60px !important;
        }
      }
    }
  }
}
