.ProjectIdInspection {
  &__guide {
    font-size: 16px;
    margin-bottom: 12px;
  }
  &__actions {
    display: flex;
    justify-content: center;
    margin: 48px 0;
    button {
      margin: 0 48px;
      width: 400px;
    }
  }
}
