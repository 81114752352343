.EstimateTable {
  .ant-table-thead {
    tr > th {
      border-radius: 0 !important;
    }
    .ant-table-cell {
      text-align: center;
      background-color: #e6e4e4;
    }
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        height: 54px;
        padding: 4px 4px !important;
      }
    }
  }
  &__col {
    text-align: center;
    &__amountReference {
      width: 5%;
      text-align: center;
      padding: 4px 16px !important;
    }
    &__itemName {
      width: 24%;
    }
    &__quantity {
      width: 8%;
      text-align: right;
    }
    &__unit {
      width: 8%;
    }
    &__unitPrice {
      width: 10%;
      text-align: right;
    }
    &__amount {
      max-width: 10%;
      text-align: right;
    }
    &__remarks {
      width: 30%;
    }
    &__delete {
      width: 5%;
      text-align: center;
    }
  }
}
