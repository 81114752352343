.ProposedEstimates {
  margin-top: 12px;
  &__titleContainer {
    display: flex;
    justify-content: space-between;
    &__title {
      font-weight: bold;
      margin-bottom: 4px;
    }
    &__changedText {
      color: red;
    }
  }
  &__totalAmount {
    display: flex;
    justify-content: flex-end;
    margin-right: 4px;
    margin-top: 10px;
    &__label {
      margin-right: 8px;
    }
  }
}
