.FormItem {
  margin-top: 30px;
  text-align: left;
  &--required {
    .FormItem__label {
      &::before {
        display: none;
      }
      &::after {
        content: "必須";
        display: inline-block;
        color: #fff;
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        background-color: #b54055;
        margin-left: 8px;
        padding: 1px 4px 0;
        transform: translateY(-1px);
      }
    }
  }
  &--labelless {
    margin-top: 0;
  }
  &__label {
    display: block;
    font-size: 12px;
    margin-bottom: -18px;
    transform: translateY(-24px);
    font-size: 14px;
    color: #0f692b;
    font-weight: bold;
  }
  .errorMessage {
    color: red;
    font-size: 10px;
    line-height: 20px;
    margin: 0;
    &__offset {
      margin-bottom: -20px;
    }
  }
}

.InputPrice {
  input {
    text-align: right !important;
    margin-right: -12px !important;
  }
  .ant-input-suffix {
    transform: translate(2.5em, 2px);
    font-size: 13px;
  }
}
