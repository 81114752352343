.JobTypeTag {
  background-color: rgb(243, 252, 246);
  color: #10692a;
  border-color: #10692a;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 10px;
  border-radius: 16px;
  margin: 2px 4px;
}
