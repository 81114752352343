.Gallery {
  height: 380px;
  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
    &__noImageText {
      font-size: 18px;
      margin-top: 190px;
      text-align: center;
    }
    &__carouselContainer {
      width: 100% !important;
    }
  }
}
