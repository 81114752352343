.ChatRoomList {
  &__item {
    min-height: 80px;
    .ant-list-item-meta-title {
      font-size: 16px !important;
    }
  }
  &__item:hover {
    background-color: #f7f7f7;
    cursor: pointer;
  }
}
