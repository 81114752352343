.InvoiceList {
  &__filterContainer {
    display: flex;
    padding: 10px;
    gap: 10px;
    &__form {
      width: 100%;
    }
  }
  &__expandedRow > * {
    background-color: rgb(243, 252, 246) !important;
    padding: 8px 20px 20px !important;
  }
}
