.Chat {
  &__button {
    width: 100px;
    height: 100px;
    &__description {
      display: flex;
      flex-direction: column;
      justify-content: center !important;
      align-items: center !important;
      &__text {
        margin-top: 12px;
        font-size: 16px;
        text-align: center;
      }
      &__icon {
        padding-top: 6px;
        font-size: 24px !important;
        width: 24px !important;
      }
    }
  }
}
