.WorkerCard {
  width: 360px;
  margin: 12px 0;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__name {
      width: 90%;
      overflow-wrap: normal !important;
    }
    &__chat {
      width: 10%;
      &__button {
        padding: 0;
      }
      &__icon {
        font-size: 20px;
      }
    }
  }
  &__type {
    margin-top: 4px;
  }
  &__rate {
    > * {
      vertical-align: baseline;
    }
    &__star {
      font-size: 18px;
    }
    &__number {
      margin-left: 8px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 600;
    }
    &__workCount {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
      margin-left: 12px;
    }
  }
  &__jobTypes {
    margin: 12px 0;
  }
  &__apply {
    margin-top: 24px;
    color: black;
    &__workDateRange {
      &__label {
        font-weight: bold;
        margin-right: 20px;
      }
    }
  }
}

.ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 4px !important;
}

.ant-card-meta-avatar {
  padding-right: 8px !important;
}
