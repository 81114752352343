.Comment {
  &__name {
    font-size: 12px;
  }
  &__content {
    margin-top: 4px;
    background: #eee;
    padding: 0.5em;
  }
  &__body {
    font-size: 14px;
  }
  &__date {
    margin-top: 8px;
    font-size: 10px;
    color: #666;
  }
  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
  }
  & + & {
    margin-top: 12px;
  }
}
