.WorkerHeader {
  padding: 0 24px;
  height: 72px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dadada;
  background-color: #fff;
  position: sticky !important;
  top: 0 !important;
  z-index: 100;
  &__leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      font-size: 20px;
      padding: 0 12px;
      color: rgb(0, 0, 0);
    }
    &__title {
      display: flex;
      align-items: center;
      &__workerName {
        margin-right: 16px;
      }
    }
  }
}
