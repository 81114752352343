.Estimates {
  &__overviewContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    &__requestEstimateProposal {
      display: flex;
      .anticon-check {
        color: #10692a;
      }
      &__text {
        margin: 0 4px;
      }
    }
  }
  &__totalAmountTableContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
